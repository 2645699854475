<template>
  <div class="tooltip">
    <div class="tooltip-header">
      <img :src="require(`../assets/img/Art/${icon}`)" class="tooltip-icon" />
      <div class="tooltip-header-text">
        <span class="tooltip-title">{{ title }}</span>
        <div class="tooltip-cost">
          <span class="tooltip-cost-item" v-if="cost.f">
            <img :src="require('../assets/img/Art/UserInterface/InGame/ResourceFood_ua.webp')" />
            {{ cost.f }}
          </span>
          <span class="tooltip-cost-item" v-if="cost.w">
            <img :src="require('../assets/img/Art/UserInterface/InGame/ResourceWood_ua.webp')" />
            {{ cost.w }}
          </span>
          <span class="tooltip-cost-item" v-if="cost.g">
            <img :src="require('../assets/img/Art/UserInterface/InGame/ResourceGold_ua.webp')" />
            {{ cost.g }}
          </span>
          <span class="tooltip-cost-item" v-if="cost.s">
            <img :src="require('../assets/img/Art/UserInterface/InGame/ResourceStone_ua.webp')" />
            {{ cost.s }}
          </span>
          <span class="tooltip-cost-item" v-if="cost.p">
            <img
              :src="require('../assets/img/Art/UserInterface/InGame/ResourcePopulationHover_ua.webp')"
            />
            {{ cost.p }}
          </span>
        </div>
      </div>
    </div>
    <div class="tooltip-content">
      <span class="tooltip-description" v-html="$options.filters.highlight(description)"></span>
      <ul class="upgrades-list" v-if="upgrades">
        <li
          v-for="(upgrade, key) in upgrades"
          v-bind:key="key"
          v-bind:class="{
                        'text-positive': upgrade.positive === true,
                        'text-negative': upgrade.positive === false,
                        'text-neutral': upgrade.positive === null
                    }"
          v-html="$options.filters.highlight(upgrade.text)"
        ></li>
      </ul>
      <div class="stats-list" v-if="stats">
        <div class="stat-row">
          <span class="stat-title" v-if="type">Type:</span>
          <span class="stat-value" v-if="type" v-html="$options.filters.highlight(type)"></span>
        </div>
        <div v-for="key in orderedStatsKeys" v-bind:key="key" class="stat-row">
          <span class="stat-title" v-html="$options.filters.highlight((C.unitStats[key] || {text: key}).text) + ':'"></span>
          <span class="stat-value">{{ stats[key] }}</span>
          <img
            v-if="C.unitStats[key]"
            :src="require(`../assets/img/Art/UserInterface/Icons/UnitStats/${C.unitStats[key].icon}`)"
            class="stat-icon"
          />
        </div>
      </div>
    </div>
    <div v-if="cost.t" class="stats-list research-time">
      <div class="stat-row">
        <span class="stat-title">Research Time:</span>
        <span class="stat-value">{{ cost.t }}s</span>
        <img
          :src="require('../assets/img/Art/UserInterface/Icons/UnitStats/US_BuildTime_ua.webp')"
          class="stat-icon"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    title: String,
    icon: String,
    description: String,
    type: String,
    cost: {
      type: Object,
      default: function() {
        return {
          food: 0,
          wood: 0,
          gold: 0,
          stone: 0,
          pop: 0,
          time: 0
        }
      }
    },
    upgrades: Array,
    // Example value:
    // [
    //     {text: '+x% upgrade', positive: true},
    //     {text: '-x% downgrade', positive: false},
    //     {text: 'Neutral', positive: null},
    // ],
    stats: Object
    // Example value:
    // {
    //     MaxHitpoints: 2000,
    //     Damage: 10.5,
    //     TrainTime: '12s',
    // },
  },
  computed: {
    orderedStatsKeys: function() {
      return Object.keys(this.stats).sort((a, b) => {
        return this.C.statsOrder[a] - this.C.statsOrder[b]
      }).filter(k => this.stats[k] != null)
    }
  }
}
</script>
<style scoped lang="scss">
.tooltip {
  box-shadow: 0 2px 4px -1px rgba(25, 43, 51, 0.2),
    0 4px 5px 0 rgba(25, 43, 51, 0.14), 0 1px 10px 0 rgba(25, 43, 51, 0.12);
  border: 3px solid $border-color-tooltip;
  border-radius: $border-radius-tooltip;
  margin: 0;
  background: $background-color-tooltip;
  padding: 5px;
  font-size: $font-size;
  color: $font-color;
  text-align: left;
  float: left;
  max-width: $tooltip-max-width;
  min-width: $tooltip-min-width;

  .tooltip-content {
    display: flex;
    flex-direction: column;

    .tooltip-description {
      margin-bottom: 0.75rem;
    }
  }

  .tooltip-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;

    .tooltip-header-text {
      display: flex;
      flex-direction: column;
    }
  }

  .tooltip-title {
    font-size: $font-size-lg;
    font-weight: 700;
  }

  .tooltip-title,
  .tooltip-cost {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }

  .tooltip-cost {
    .tooltip-cost-item:not(:last-child) {
      margin-right: 0.5rem;
    }

    .tooltip-cost-item > img {
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-right: 0.075rem;
    }
  }

  .tooltip-icon {
    display: inline-block;
    width: 54px;
    height: 54px;
    margin-right: 0.75rem;
  }

  .upgrades-list {
    line-height: $line-height-ul;
    list-style: none;
    position: relative;
    padding-left: 1rem;
    margin: 0;

    li {
      position: relative;
    }

    li:before {
      content: "";
      position: absolute;
      top: $line-height-ul * 0.5;
      transform: translateY(-50%);
      left: -0.75rem;
      background: $font-color;
      border-radius: 50%;
      width: 0.375rem;
      height: 0.375rem;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      box-shadow: 1px 0 1px #000;
    }
  }

  .stats-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    .stat-row {
      display: flex;
      align-items: center;

      .stat-title {
        flex-grow: 1;
        line-height: 18px;
        margin-right: 5px;
      }

      .stat-value {
        text-align: right;
        line-height: 18px;
      }

      .stat-icon {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }

    .stat-row:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .research-time {
    margin-top: 10px;
  }
}
</style>