<template>
  <div class="techtree-element" @mouseleave="onMouseLeave" @mousemove="onMouseMove">
    <img
      :src="require(`../assets/img/Art/${item.icon}`)"
      :class="{'small-bldg-img': item.title.indexOf('Farm') != -1 || item.title.indexOf('Gold Mine') != -1 || item.title.indexOf('Millarium') != -1 }"
    />
  </div>
</template>
<script>
export default {
  name: "TechTreeItem",
  props: {
    item: Object
  },
  methods: {
    onMouseLeave() {
      window.GlobalEvent.$emit("TechTreeItemHover", null)
    },
    onMouseMove(event) {
      window.GlobalEvent.$emit("TechTreeItemHoverMove", event, this.item)
    }
  }
}
</script>
<style scoped lang="scss">
.small-bldg-img {
  width: 100px;
  height: 100px;
}
</style>
