<template>
  <div class="techtree-tab">
    <img
      class="techtree-background"
      :src="require(`../assets/img/${C.backgrounds[tab].replace(/%CIV%/g, C.civs[civ])}`)"
    />
    <div class="techtree-age-container">
      <div v-for="i in 4" :class="['techtree-elements-container', 'age-' + i]" :key="i">
        <techtreeitem
          v-for="item in C.data[civ][tab][i - 1]"
          :key="item.tech"
          :class="['tt-col-' + item.pos.c, 'tt-row-' + item.pos.r]"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TechTreeTab",
  props: {
    civ: { type: String, required: true },
    tab: { type: String, required: true } // economy, military or utility
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.techtree-tab {
  width: 1024px;
  height: 1024px;
  position: relative;

  .techtree-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .techtree-age-container {
    width: 1024px;
    height: 1024px;
    position: absolute;

    .techtree-elements-container {
      z-index: 1;
      position: relative;
      height: 110.5px;
      width: 929px;
      margin: auto;
      top: 143.5px;
      margin-bottom: 63.5px;
      left: 13px;

      .techtree-element {
        position: absolute;
        display: flex;
        transform: translate(-50%, -50%);
      }
    }
  }
}

$maxcol: 58;
@for $i from 1 through $maxcol {
  .tt-col-#{$i} {
    left: ($i / $maxcol) * 100%;
  }
}

.tt-col-6-5 {
  left: (6.5 / $maxcol) * 100%;
}

$maxrow: 7;
@for $i from 1 through $maxrow {
  .tt-row-#{$i} {
    top: (($i - 1) / $maxrow) * 100%;
  }
}

.tt-row-4-5 {
  top: ((4.5 - 1) / $maxrow) * 100%;
}

.tt-row-5-5 {
  top: ((5.5 - 1) / $maxrow) * 100%;
}

.tt-row-8-5 {
  top: ((8.5 - 1) / $maxrow) * 100%;
}
</style>
